import { AppBar, Avatar, Box, Tooltip } from '@mui/material';
import React from 'react';
import LorieIcon from 'src/shared/components/Common/LorieIcon';
import { UserInformationMessageBanner } from '../shared/components/Common/UserInformationMessageBanner';
import { UserInformationMessageLightModel } from './Referentials/UserInformationMessagesReferential/models/UserInformationMessageLightModel';

interface NavMenuState {
    collapsed: boolean
}

interface NavMenuProperties {
    role: string,
    firstName: string,
    lastName: string,
    userInfoMessages: Array<UserInformationMessageLightModel>
}
export class ExternalMenu extends React.Component<NavMenuProperties, NavMenuState> {
    envName: string = window.location.origin.includes("rec-isoprod", -1) ? "ISO-PROD RECETTTE" : (window.location.origin.includes("rec-2nd", -1) ? "RECETTE-2" :
        (window.location.origin.includes("rec", -1) ? "RECETTE" : (window.location.origin.includes("dev-2nd", -1) ? "DEV-2" : window.location.origin.includes("dev", -1) ? "DEV" : (window.location.origin.includes("local", -1) ? "LOCAL" : ""))));

    constructor(props: NavMenuProperties) {
        super(props);
        this.state = {
            collapsed: true
        };
    }

    getInitialsUser(firstName: string, lastName: string): string {
        return `${firstName && firstName.length > 0 ? firstName[0] : ''}${lastName && lastName.length > 0 ? lastName[0] : ''}`;
    }

    render() {
        const { firstName, lastName, userInfoMessages } = this.props;

        return (
            <AppBar className="screenOnly header-menu" position="fixed">
                <Box display="flex" flexWrap="nowrap" flexDirection="row" alignItems="center">
                    <Box width="75%" display="flex" pl={3} pr={2} justifyContent="flex-start" flexDirection="row" alignItems="center">
                        <Box>
                            <LorieIcon name="logoLorie" height="55px" width="55px" />
                        </Box>
                    </Box>
                    <Box width="25%" display="flex" flexDirection="row" flexWrap="nowrap" justifyContent="flex-end" pl={2} pr={2} pt={1} pb={1} alignItems="center">
                        <Box pr={1}>
                            <span className="text-red rec-env-label">{this.envName}</span>
                        </Box>
                        <Box pl={2}>
                            <Tooltip title={`${firstName} ${lastName}`} placement='left'>
                                <Avatar className="initial-profile">
                                    {this.getInitialsUser(firstName, lastName)}
                                </Avatar>
                            </Tooltip>
                        </Box>
                    </Box>
                </Box>
                {(userInfoMessages?.length ?? 0) > 0 && <Box bgcolor='lightgray'><UserInformationMessageBanner messages={this.props.userInfoMessages} /></Box>}
            </AppBar>
        );
    }
}
