import React, { useEffect, useMemo } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import Select from 'react-select';
import { ErrorModel, UserInformationMessageLightModel } from '../models/UserInformationMessageLightModel';
import { CalendarComponent } from '../../../../shared/components/Common/CalendarComponent';
import { UserInformationMessageBanner } from '../../../../shared/components/Common/UserInformationMessageBanner';

interface AddUserInformationMessageComponentProps {
    item: UserInformationMessageLightModel,
    onSave: (item: UserInformationMessageLightModel) => Array<ErrorModel>,
    onCancel: () => void
}

export interface LabelValuesModel {
    label: string,
    value: string
}

export const AddUserInformationMessageComponent = (props: AddUserInformationMessageComponentProps): JSX.Element => {
    const [errors, setErrors] = React.useState<ErrorModel[]>([]);
    const [item, setItem] = React.useState<UserInformationMessageLightModel>(undefined);

    useEffect(() => {
        setItem({ ...props.item, startDate: new Date(props.item.startDate).getDayStart(), endDate: new Date(props.item.endDate).getDayEnd() });
    }, [props.item]);

    const colorValues: LabelValuesModel[] = useMemo(() => {
        return [
            { value: "GREEN", label: "Vert" },
            { value: "RED", label: "Rouge" }
        ];
    }, []);

    const rolesValues: LabelValuesModel[] = useMemo(() => {
        return [
            { value: "ADM", label: "Administrator" },
            { value: "CTX", label: "ContractManager" },
            { value: "DIS", label: "Dispatcher" },
            { value: "CCH", label: "JobForeman" },
            { value: "LOG", label: "Logistician" },
            { value: "PRD", label: "ProductionForeman" },
            { value: "TRA", label: "TransporterManager" },
        ];
    }, []);

    const msgColorChoice = useMemo(() => {
        if (!item) return <></>;
        return (<div className="msgColor">
            {colorValues.map((c) => {
                return (<div key={`msgColor_${c.value}`}>
                    <input type="radio" name="messageColor" id={`rdBtn_msgColor_${c.value}`} value={c.value}
                        checked={item.messageColor === c.value} onChange={() => setItem({ ...item, messageColor: c.value })} />
                    <label htmlFor={`rdBtn_msgColor_${c.value}`} style={{ backgroundColor: c.value.toLowerCase() }} title={c.label}>&nbsp;</label>
                </div>);
            })}
        </div>);
    }, [item, colorValues]);

    const save = () => {
        const errors = props.onSave(item);
        if (errors.length > 0) {
            setErrors(errors);
        }
    }

    if (!item) return (<></>)

    return (
        <Dialog
            open={true}
            disableEscapeKeyDown
            aria-modal="false"
            onClose={props.onCancel}
            scroll="paper"
            className="simple-dialog-new-add-user-info-message"
        >
            <DialogTitle id="scroll-dialog-title">
                {!item.id && <span>Ajouter un nouveau message à destination des utilisateurs</span>}
                {!!item.id && <span>Modifier le message à destination des utilisateurs</span>}
            </DialogTitle>
            <DialogContent dividers>
                <Box display="grid" gap={4} className="dialog-add">
                    <div>
                        <div className="title">
                            Dates
                        </div>
                        <CalendarComponent
                            handlerFromChildToParent={d => setItem({ ...item, startDate: d.start?.getDayStart(), endDate: d.end?.getDayEnd() })}
                            startDate={item.startDate}
                            endDate={item.endDate}
                            maximumNumberOfMonths={2}
                            calendarId="userInfoMessage-daterange-picker"
                            isValid={errors.some(e => e.code === "ERR_DATES")}
                            handleIsValidChanged={(isValid) => setErrors(isValid ? errors.filter(e => e.code !== "ERR_DATES") : [...errors, { code: "ERR_DATES", label: "" }])}
                        />
                    </div>
                    <div>
                        <div className="title">Rôles des utilisateurs concernés</div>
                        <div>
                            <Select
                                className={errors.some(e => e.code === "ERR_ROLES") ? "react-select-error" : ""}
                                isClearable
                                isMulti
                                menuPosition="fixed"
                                menuShouldBlockScroll={true}
                                isDisabled={false}
                                options={rolesValues}
                                value={(item.roles ?? "").split(',').map(r => rolesValues.find(r1 => r1.value === r))}
                                onChange={(e) => { setItem({ ...item, roles: e?.map(v => v.value).join(',') ?? "" }) }}
                            />
                        </div>
                    </div>
                    <div>
                        <div className="headerMessage">
                            <div className="title">
                                Message
                            </div>
                            {msgColorChoice}
                        </div>
                        <TextField value={item.message} onChange={(e) => setItem({ ...item, message: e.target.value })} fullWidth multiline error={errors.some(e => e.code === "ERR_MESSAGE")} />
                        <div className="subtitle">
                            Exemples de syntaxe : **gras**, *italique*, [lien](http://www.example.com)
                        </div>
                    </div>

                    <div>
                        <div className="title">
                            Aperçu
                        </div>
                        <div className="bannerPreview">
                            {item.message === "" && <div>&nbsp;</div>}
                            {item.message !== "" && <UserInformationMessageBanner messages={[item]} />}
                        </div>
                    </div>


                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onCancel} color="primary">
                    Annuler
                </Button>
                <Button color="primary" onClick={() => save()}>Sauvegarder</Button>
            </DialogActions>
        </Dialog>
    );
}
