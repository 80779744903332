import { useMatomo } from '@datapunt/matomo-tracker-react';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ScaleLoader } from 'react-spinners';
import { Container } from 'reactstrap';
import { ExternalMenu } from './ExternalMenu';
import { UserInformationMessageLightModel } from './Referentials/UserInformationMessagesReferential/models/UserInformationMessageLightModel';

//Nécessaire pour que l'application soit supporté par IE11
//import 'babel-polyfill';

export interface LayoutProps {
    children?: React.ReactNode,
    loadingLayout: boolean,
    role: string,
    firstName: string,
    lastName: string,
    userName: string,
    userInfoMessages: Array<UserInformationMessageLightModel>
}

export const ExternalLayout = (props: LayoutProps) => {
    const location = useLocation();
    const isAuthorizedUser = !!props.role;

    const { trackPageView } = useMatomo();
    useEffect(() => {
        trackPageView({
            href: location.pathname,
            customDimensions: [
                {
                    id: 1,
                    value: location.search,
                }
            ]
        });
    }, [location, props.userName]);

    const authorizedUser = props.loadingLayout
        ? <div className='sweet-loading spinnerClass'>
            <ScaleLoader
                width={5}
                height={20}
                radius={50}
                color={'#000000'}
                loading={props.loadingLayout}
            />
        </div>
        : isAuthorizedUser
            ?
            <div className="external-layout">
                <ExternalMenu role={props.role} firstName={props.firstName} lastName={props.lastName} userInfoMessages={props.userInfoMessages} />
                <Container fluid>
                    {props.children}
                </Container>
            </div>
            :
            <div className="jumbotron">
                <h1>Accès Refusé</h1>
                <p>Votre compte n'a pas les permissions requises ou est inconnu de l'application.</p>
            </div>;

    return (
        <div>
            {authorizedUser}
        </div>
    );
}
