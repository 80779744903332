import React from 'react';
import { UserInformationMessageLightModel } from "../../../views/Referentials/UserInformationMessagesReferential/models/UserInformationMessageLightModel";
import ReactMarkdown from 'react-markdown';

interface UserInformationMessageBannerProps {
    messages: Array<UserInformationMessageLightModel>;
}

export const UserInformationMessageBanner = (props: UserInformationMessageBannerProps): JSX.Element => {
    if (!props.messages || props.messages.length === 0) return (<></>);

    return (
        <>
            <style>
                {`
                .marquee {
                    display: flex;
                    width: 100%;
                    overflow: hidden;
                }

                .marquee-content {
                    display: inline-block;
                    width: 100%;
                    overflow: hidden;
                    white-space: nowrap;
                    animation: marquee ${props.messages.length * 15}s linear infinite;
                }

                .marquee-content p {
                    margin: 0 25px;
                    padding: 0;
                }

                @keyframes marquee {
                    0% { transform: translate(100%, 0); }
                    100% { transform: translate(-100%, 0); }
                }
                `}
            </style>
            <div className="marquee">
                <div className="marquee-content">
                    {props.messages.map((message, index) => (
                        <span key={index} className={`marquee-item`} style={{ display: 'inline-block', color: message.messageColor }}>
                            <ReactMarkdown>{message.message.replaceAll("\n", "")}</ReactMarkdown>
                        </span>
                    ))}
                </div>
            </div>
        </>
    );
};